<template>
  <div v-if="subscriptionPlans.length === 0">
    {{ $t('views.top-bar.access-control.access-rules.list.category.all-customers') }}
  </div>
  <div v-else>
    <template v-for="(subscriptionPlan, i) of subscriptionPlans">
      {{ parseInt(i) === subscriptionPlans.length - 1 ? subscriptionPlan.name : subscriptionPlan.name + ', ' }}
    </template>
  </div>
</template>
<script>
  export default {
    props: {
      subscriptionPlans: {
        type: Array,
        default: () => []
      }
    }
  }
</script>
